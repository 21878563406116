import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 19.6382L20.8825 17.0284C20.605 16.3822 20.1237 15.4529 19.4425 14.7017C18.7725 13.9621 17.97 13.4588 17 13.4588C14.905 13.4588 13.25 15.3931 13.25 17.7184C13.25 19.485 13.9425 20.7323 15.585 22.6345C16.0062 23.1217 16.4862 23.6484 17.0175 24.229C18.4025 25.7461 20.125 27.6337 22 30.1574C23.875 27.6337 25.5975 25.7461 26.9825 24.229C27.5138 23.6484 27.995 23.1203 28.415 22.6345C30.0575 20.7323 30.75 19.485 30.75 17.7184C30.75 15.3931 29.095 13.4588 27 13.4588C26.0288 13.4588 25.2275 13.9621 24.5575 14.7017C23.8762 15.4529 23.395 16.3822 23.1175 17.0284L22 19.6382ZM22.49 31.7344C22.4298 31.8176 22.3548 31.8845 22.2701 31.9304C22.1855 31.9762 22.0933 32 22 32C21.9067 32 21.8145 31.9762 21.7299 31.9304C21.6452 31.8845 21.5702 31.8176 21.51 31.7344C19.5087 28.9598 17.6975 26.9758 16.2063 25.3435C13.625 22.5149 12 20.7366 12 17.7184C12 14.5602 14.2375 12 17 12C19 12 20.3987 13.5317 21.255 14.9292C21.58 15.4617 21.8275 15.9737 22 16.3763C22.2162 15.8724 22.4653 15.3886 22.745 14.9292C23.6012 13.5303 25 12 27 12C29.7625 12 32 14.5602 32 17.7184C32 20.7366 30.375 22.5149 27.7938 25.3435C26.3025 26.9773 24.4913 28.9627 22.49 31.7344Z"
      fill="black"
    />
  </svg>
);
export default SVGComponent;

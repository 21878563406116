import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1578 16.7368C25.1578 15.8993 24.8251 15.0961 24.2329 14.5039C23.6406 13.9116 22.8374 13.5789 21.9999 13.5789C21.1624 13.5789 20.3591 13.9116 19.7669 14.5039C19.1747 15.0961 18.842 15.8993 18.842 16.7368C18.842 17.5744 19.1747 18.3776 19.7669 18.9698C20.3591 19.562 21.1624 19.8947 21.9999 19.8947C22.8374 19.8947 23.6406 19.562 24.2329 18.9698C24.8251 18.3776 25.1578 17.5744 25.1578 16.7368ZM21.9999 12.5263C23.1166 12.5263 24.1876 12.9699 24.9772 13.7595C25.7668 14.5492 26.2104 15.6201 26.2104 16.7368C26.2104 17.8535 25.7668 18.9245 24.9772 19.7141C24.1876 20.5038 23.1166 20.9474 21.9999 20.9474C20.8832 20.9474 19.8122 20.5038 19.0226 19.7141C18.233 18.9245 17.7894 17.8535 17.7894 16.7368C17.7894 15.6201 18.233 14.5492 19.0226 13.7595C19.8122 12.9699 20.8832 12.5263 21.9999 12.5263ZM16.1262 25.3158C14.9473 26.1789 14.1999 27.6737 14.1157 30.421H29.8841C29.7999 27.6842 29.0525 26.1789 27.8841 25.3158C26.5683 24.3684 24.6315 24.1053 21.9999 24.1053C19.3683 24.1053 17.4209 24.3789 16.1262 25.3158ZM21.9999 23.0526C24.6315 23.0526 26.8946 23.3053 28.4946 24.4737C30.1367 25.6631 30.9473 27.7158 30.9473 30.9474V31.4737H13.0525V30.9474C13.0525 27.7158 13.8631 25.6631 15.5052 24.4737C17.1052 23.3158 19.3683 23.0526 21.9999 23.0526Z"
      fill="black"
    />
  </svg>
);
export default SVGComponent;

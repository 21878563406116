import {
  Microphone,
  Speaker,
  HardDisk,
  USB,
  Appliances,
  Mobile,
  Laptop,
  Speaker1,
  GoPro,
  WirelessMouse,
  Router,
  Tablet,
  LCD,
  SmartWatch,
  Laptop1,
  MemoryCardReader,
  Printer,
  Projector,
} from "../assets/AllProducts/AllProducts";

const FeatureProductsData = [
  {
    id: "product1",
    img: Microphone,
    header: "Mic",
    price: "₹500",
    subdescription:
      "Mic Mount with Pop Filter minimizes noise, vibrations, compatible with Rode NT1-A, NT2-A, Podcaster microphones, in grey.",
    reviewCount: "(97)",
    description1:
      "The @DawnRays Condenser Mic Mount with Pop Filter effectively reduces noise and vibrations, compatible with Rode NT1-A, NT2-A, Podcaster microphones in black. The shock mount employs elastic bands to suspend the microphone, isolating it from external disturbances for clean recordings. It features a double-layered mesh pop filter that efficiently minimizes popping sounds and sibilance, enhancing recording quality.",
    description2:
      "Compatible with MXL, Samson, Behringer, and other microphone models, the mount is durably constructed for long-term reliability, suitable for both professional studios and home setups. This versatile combination provides essential solutions for high-quality recordings in various environments, ensuring consistent performance for professionals and hobbyists alike. With its capability to mitigate unwanted noise and vibrations, users can anticipate improved clarity and fidelity in their recordings, regardless of the microphone type utilized.",
    additionalInfoFeatures: [
      "1 x Spider Shock Mount",
      "3 x Difference Size Bottom Screws",
      "1 x Screw Adapter",
      "3 x Replacement Elastic Band",
      "x 5 small Rubber Band",
      "1 x Pop Filter",
    ],
    additionalInfoHighlight: [
      "Color is Grey",
      "Type: Spider Shock Mount with Pop Filter compatible with Rode NT1-A NT2-A Podcaster",
    ],
  },
  {
    id: "product2",
    img: Speaker,
    header: "Speaker - 15w",
    price: "₹300",
    subdescription:
      "Alphabin hut 8 ohm 15 Watt Speaker Module Educational Electronic Hobby Kit",
    reviewCount: "(97)",
    description1:
      "This speaker boasts an impedance of 8 ohms and a power handling capacity of 15 watts, ensuring clear and powerful audio reproduction. With its robust construction and high-quality components, it delivers rich and immersive sound experiences across various applications. Whether used in home audio setups, multimedia systems, or as part of a portable speaker configuration, this speaker excels in delivering crisp highs, detailed midrange, and impactful bass frequencies.",
    description2:
      "To further showcase its features and capabilities, a product video is available for viewing on the 'Alphabin hut' YouTube channel. This video provides a comprehensive overview of the speaker's design, performance, and versatility, allowing potential customers to make informed purchasing decisions. Upgrade your sound system with this reliable and high-performance speaker from 'Alphabin hut'.",
    additionalInfoFeatures: ["1 Piece 8 ohm 15W Speaker"],
    additionalInfoHighlight: [
      "Brand: Alphabin hut",
      "Model Number: 8 ohm 15 Watt Speaker Module",
      "Type: Educational",
      "ROHS Complaint: Yes",
      "Material: Steel",
    ],
  },
  {
    id: "product3",
    img: HardDisk,
    header: "HardDisk - 4TB",
    price: "₹400",
    subdescription:
      "AB BLACK SURVEILLANCE 4 TB Surveillance Systems Internal Hard Disk Drive (HDD) (WD40PURZ) (Interface: SATA, Form Factor: 3.5 inch)",
    reviewCount: "(97)",
    description1:
      "The AB Black hard drives are meticulously crafted for enthusiasts and creative professionals seeking top-tier performance. These compact 2.5-inch mobile drives are tailor-made for demanding applications such as photo and video editing, gaming, and high-powered PCs. Engineered with cutting-edge technology, they deliver exceptional speed, reliability, and responsiveness, empowering users to tackle intensive tasks with ease. Whether you're a content creator pushing the boundaries of multimedia production or a gaming enthusiast seeking lag-free performance, these drives excel in delivering unparalleled performance.",
    description2:
      "With robust construction and advanced features, they offer peace of mind for those who demand nothing but the best from their storage solutions. Elevate your computing experience with the AB Black hard drives, where leading-edge performance meets uncompromising reliability.",
    additionalInfoFeatures: ["1 X SATA HARD DISK"],
    additionalInfoHighlight: [
      "Brand: AB",
      "Model ID: AB68PURZ",
      "Series: BLACK SURVEILLANCE",
    ],
  },
  {
    id: "product4",
    img: USB,
    header: "Pen Drive - 32GB",
    price: "₹450",
    subdescription:
      "Alphabin 32GB USB 2.0 Flash Drive Thumb Drives Bulk Jump Drive Zip Drive Memory stick with LED U05 USB 2.0  design Black (LV032KGVS2U05V1KAM)",
    reviewCount: "(97)",
    description1:
      "The Alphabib 32GB USB 2.0 Flash Drive is a reliable and convenient storage solution designed for everyday use. With its sleek black design and compact size, it offers portability and style. Ideal for storing and transferring files, documents, photos, and videos, this thumb drive is perfect for both personal and professional use. The LED indicator provides visual confirmation of data transfer activity, ensuring peace of mind. Its USB 2.0 interface offers compatibility with a wide range of devices, making it versatile and practical.",
    description2:
      "Whether you're backing up important files or carrying multimedia content on the go, this Alphabin Flash Drive delivers dependable performance and ample storage capacity. Upgrade your storage solution with this high-quality memory stick.",
    additionalInfoFeatures: ["1 Piece 32GB USB 2.0", "1 X SATA HARD DISK"],
    additionalInfoHighlight: [
      "Brand: Alphabin",
      "Model ID: LV032KGVS2U05V1KAM",
      "Product Dimensions: 1.8 x 4.93 x 0.66 cm; 9.07 Grams",
    ],
  },
  {
    id: "product5",
    img: GoPro,
    header: "Go Pro",
    price: "₹500",
    subdescription:
      "Elevate your adventures with high-definition action capture and unrivaled durability for memories that last a lifetime",
    reviewCount: "(97)",
    description1:
      "GoPro stands as a premier brand in action cameras, renowned for its unparalleled ability to capture high-definition footage of the most thrilling adventures. Designed to endure extreme conditions, its compact and durable build renders it perfect for pursuits such as surfing, skiing, and hiking.",
    description2:
      "Boasting up to 4K resolution, footage captured is characterized by crisp detail, while advanced stabilization technology ensures smooth shots, even in the roughest terrains. Offering versatile mounting options, GoPro facilitates creative angles, while wireless connectivity enables seamless sharing and remote control through the GoPro app.",
    additionalInfoFeatures: [
      "Waterproof Design: GoPro cameras are engineered to be waterproof, enabling users to capture stunning footage both above and below the surface without worry",
      "TimeWarp 3.0: With TimeWarp 3.0, users can create captivating time-lapse videos with stabilized footage, adding dynamic visual elements to their content",
      "SuperPhoto Mode: GoPro's SuperPhoto mode automatically enhances images with HDR, local tone mapping, and noise reduction, ensuring stunning photos in any lighting condition",
      "Voice Control: Enjoy hands-free operation with voice commands, allowing users to control their GoPro camera effortlessly, even in the midst of action-packed adventures",
    ],
    additionalInfoHighlight: [
      "Maximum Resolution: Capture stunning images at 23 MP for incredible detail and clarity",
      "Video Capture: Record immersive videos in resolutions up to 5.3K at 60fps and 4K at 120fps, offering wide-angle views",
      "Optical Sensor: Benefit from a high-performance 23 MP optical sensor for superior image quality",
      "Power Source: Operate with ease using battery power for convenience and portability",
      "Minimum Focal Length: Enjoy versatile shooting options with a minimum focal length of 28 millimeters for varied perspectives",
    ],
  },
  {
    id: "product6",
    img: WirelessMouse,
    header: "Wireless mouse",
    price: "₹500",
    subdescription:
      "Effortlessly navigate your digital realm with the precision and convenience of a wireless mouse.",
    reviewCount: "(97)",
    description1:
      "The wireless mouse offers unparalleled convenience and freedom, revolutionizing the way you interact with your computer. Free from cumbersome cords, it provides effortless navigation and control at your fingertips. With advanced wireless technology, it connects seamlessly to your device, eliminating the need for tangled wires and cluttered workspaces.",
    description2:
      "Its ergonomic design ensures comfortable use, reducing strain during prolonged use. Featuring precise tracking and responsive buttons, the wireless mouse delivers smooth and accurate performance for tasks ranging from everyday browsing to intricate design work. Its compact size and portability make it ideal for on-the-go use, whether you're working at your desk or traveling.",
    additionalInfoFeatures: [
      "Customizable Buttons: Tailor your mouse to suit your needs by assigning specific functions or shortcuts to the buttons, enhancing productivity",
      "Adjustable DPI Settings: Fine-tune the sensitivity of the mouse to match your preferences and the requirements of different tasks, ensuring precise cursor control",
      "Ergonomic Design: Enjoy comfortable use for extended periods with a design that supports natural hand positioning, reducing wrist strain and fatigue",
      "Wireless Connectivity: Experience freedom of movement and a clutter-free workspace with reliable wireless connection, offering convenience and flexibility",
    ],
    additionalInfoHighlight: [
      "Compact Design: Measures 16 cm in height and 12 cm in width, offering a space-saving solution",
      "Wireless Power: Functions wirelessly for enhanced convenience, eliminating the need for cords",
      "Versatile Compatibility: Compatible with laptops, PCs, and Macs, supporting various operating systems",
    ],
  },
  {
    id: "product7",
    img: Router,
    header: "Router",
    price: "₹500",
    subdescription:
      "A router is a networking device that forwards data packets between computer networks, facilitating communication between devices within a network and enabling access to the internet.",
    reviewCount: "(97)",
    description1:
      "The WiFi router is an essential networking device that connects multiple devices to the internet and enables seamless communication within a local network. Featuring advanced wireless technology, it provides high-speed internet access to devices such as smartphones, laptops, tablets, and smart home appliances.",
    description2:
      "With its robust signal strength and broad coverage, the router ensures reliable connectivity throughout your home or office. Additionally, modern routers often come equipped with features like dual-band support, MU-MIMO technology, and beamforming, optimizing network performance and minimizing interference for smoother streaming, gaming, and browsing experiences.",
    additionalInfoFeatures: [
      "Dual-Band Support: Supports both 2.4GHz and 5GHz bands for versatile performance across various devices and applications",
      "MU-MIMO Technology: Enables simultaneous communication with multiple devices, reducing latency and enhancing efficiency in crowded networks",
      "Beamforming: Directs WiFi signals to connected devices, improving signal strength, range, and stability, ideal for larger environments with multiple devices",
      "Guest Network: Provides a separate network for guests, ensuring the security of the main network while offering internet access to visitors or temporary users",
    ],
    additionalInfoHighlight: [
      "Connectivity Technology: Utilizes wireless technology for connections",
      "Number Of Ports: Provides 5 ports for wired connections",
      "Data Transfer Rate: Offers a fast data transfer rate of 867 megabits per second",
      "Wireless Standards: Supports 802.11 B, 802.11 G, and 802.11 N wireless standards",
      "Frequency Band Class: Operates on a dual-band frequency band class",
      "LAN Port Bandwidth: Each LAN port offers a bandwidth of 10/100 megabits per second",
    ],
  },
  {
    id: "product8",
    img: Tablet,
    header: "Tablet",
    price: "₹500",
    subdescription:
      "A portable computing device featuring a touchscreen interface, ideal for browsing, entertainment, productivity, and on-the-go convenience.",
    reviewCount: "(97)",
    description1:
      "A tablet is a versatile portable device that combines the functionality of a computer with the convenience of a touchscreen interface. With its compact design and lightweight construction, a tablet offers users the flexibility to stay connected, productive, and entertained while on the go.",
    description2:
      "Equipped with a vibrant display, powerful processors, and ample storage capacity, tablets are capable of handling a wide range of tasks, from web browsing and email communication to multimedia consumption and productivity applications.",
    additionalInfoFeatures: [
      "Touchscreen Interface: Intuitive navigation via touch",
      "Portability: Lightweight and easy to carry",
      "Multimedia Features: High-quality display, speakers, and cameras",
      "App Variety: Access to a diverse range of applications",
    ],
    additionalInfoHighlight: [
      "Camera: Capture stunning 12MP photos",
      "Video: Record high-quality 4K videos",
      "Keyboard Compatibility: Works seamlessly with Smart Keyboard Folio, Magic Keyboard, and Bluetooth keyboards",
      "Connector: Features a USB-C connector supporting Thunderbolt / USB 4 for fast and versatile connectivity",
    ],
  },
  {
    id: "product9",
    img: LCD,
    header: "LCD",
    price: "₹500",
    subdescription:
      "A flat-panel technology that uses liquid crystals to create images, offering sharp resolution and low power consumption for electronic displays.",
    reviewCount: "(97)",
    description1:
      "Liquid Crystal Display (LCD) technology revolutionizes visual experiences with its vibrant and immersive display capabilities. Employing liquid crystal molecules sandwiched between layers of glass, LCDs utilize electric currents to manipulate these crystals, controlling the passage of light through individual pixels.",
    description2:
      "This precise manipulation results in sharp, vivid images with accurate color reproduction across a wide spectrum. With exceptional brightness and contrast levels, LCDs ensure clarity and visibility even in varying lighting conditions. Offering versatility, LCDs find applications in a multitude of devices, including televisions, computer monitors, smartphones, and tablets.",
    additionalInfoFeatures: [
      "HDR Support: Supports High Dynamic Range (HDR) content, offering improved contrast and color accuracy for a more realistic viewing experience",
      "Smart TV Integration: Offers integration with smart TV platforms such as Android TV or Roku, providing access to a wide range of streaming services, apps, and content",
      "High Refresh Rate: Features a high refresh rate of 120Hz, reducing motion blur and ensuring smooth and fluid visuals, ideal for gaming and multimedia",
      "Voice Control: Integrates voice control functionality, allowing users to control the display, adjust settings, and access content using voice commands for added convenience and ease of use",
    ],
    additionalInfoHighlight: [
      "Image Brightness: Enhanced with AI Brightness technology for optimal viewing experiences",
      "Supported Image Types: Compatible with popular image formats including GIF and JPEG for versatile usage",
      "Aspect Ratio: Presents content in a widescreen 16:9 aspect ratio, ideal for multimedia viewing",
      "Screen Resolution: Boasts a stunning resolution of 3840 x 2160 pixels, providing crisp and detailed images",
      "Audio Encoding: Supports various audio encoding formats including DD, Dolby Atmos, and Dolby Surround for immersive sound experiences",
    ],
  },
  {
    id: "product10",
    img: SmartWatch,
    header: "Smart Watch",
    price: "₹500",
    subdescription:
      "A smartwatch is a wearable device that integrates with your smartphone, offering features like notifications, fitness tracking, and even contactless payments, all conveniently accessible from your wrist.",
    reviewCount: "(97)",
    description1:
      "A smartwatch seamlessly blends the convenience of a traditional wristwatch with the intelligence of a smartphone. Packed with an array of sensors and connectivity options, it extends beyond timekeeping to offer a plethora of functionalities.",
    description2:
      "Furthermore, smartwatches keep users informed and connected with timely notifications for calls, messages, emails, and social media updates, eliminating the need to frequently check their phones.",
    additionalInfoFeatures: [
      "Touchscreen Display: Smartwatches feature a touchscreen interface for intuitive navigation and interaction with various apps and functions",
      "Health and Fitness Tracking: Equipped with sensors to monitor health and fitness metrics such as heart rate, steps taken, calories burned, and sleep patterns",
      "Notification Alerts: Receive notifications for calls, messages, emails, and social media updates directly on the smartwatch, keeping users connected and informed",
      "Additional Features: Many smartwatches include GPS for navigation, music playback controls, and mobile payment capabilities, offering added convenience and functionality on the wrist",
    ],
    additionalInfoHighlight: [
      "Size: Compact at 1.81 inches, ideal for portability",
      "Operating System: Compatible with Android and iOS",
      "Display Size: 1.8-inch screen for clear viewing",
      "Connectivity: Utilizes Bluetooth for wireless connection",
      "Supported Applications: Includes Alarm, Calendar, Email, Find My Phone, and Messages",
      "Input Interface: Features a touch screen for intuitive navigation",
    ],
  },
  {
    id: "product11",
    img: Laptop1,
    header: "Laptop",
    price: "₹500",
    subdescription:
      "Portable computing powerhouse for work and entertainment, combining sleek design with high-performance functionality.",
    reviewCount: "(97)",
    description1:
      "A laptop is a portable computing device that combines the functionality of a desktop computer with the convenience of mobility. Featuring a keyboard, touchpad or trackpad, and built-in display, laptops offer users a versatile platform for work, study, and entertainment.",
    description2:
      "Available with various operating systems such as Windows, macOS, or Chrome OS, laptops cater to diverse user preferences and software requirements. Key features include powerful processors, ample RAM, and storage options, ensuring smooth performance for a wide range of tasks.",
    additionalInfoFeatures: [
      "Portability: Laptops offer the convenience of portability, allowing users to work or access information from anywhere, whether at home, in the office, or on the go",
      "Versatility: With a range of configurations and operating systems available, laptops cater to various user needs and preferences, providing flexibility in software compatibility and performance",
      "Connectivity: Laptops come equipped with a variety of connectivity options such as Wi-Fi, Bluetooth, USB ports, and HDMI ports, enabling seamless communication and integration with other devices and peripherals",
      "Battery Life: Many laptops feature long-lasting battery life, providing uninterrupted usage for extended periods without the need for constant charging, ensuring productivity and convenience while on the move",
    ],
    additionalInfoHighlight: [
      "Display Size: Equipped with a 39.6-centimeter display for immersive viewing experiences",
      "Hard Disk Size: Offers a spacious 512 GB hard disk for ample storage of files and data",
      "Computer Memory Size: Features 8 GB of memory for smooth multitasking and efficient performance",
      "CPU Manufacturer: Powered by an Intel processor, ensuring reliable and efficient computing",
      "CPU Speed: Clocks in at 2.8 GHz for fast and responsive processing",
    ],
  },
  {
    id: "product12",
    img: MemoryCardReader,
    header: "Memory card reader",
    price: "₹500",
    subdescription:
      "A memory card reader is a compact device that allows for easy transfer of data between memory cards and computers, offering convenient access to photos, videos, and other files stored on various types of memory cards.",
    reviewCount: "(97)",
    description1:
      "A memory card reader is a compact device designed to facilitate the transfer of data between memory cards and computers or other devices. Featuring multiple slots compatible with various types of memory cards such as SD, microSD, CF, and more, these readers offer convenience and versatility in accessing digital content.",
    description2:
      "Connected via USB or other interfaces, memory card readers enable users to swiftly transfer photos, videos, music, and other files between memory cards and devices.Many readers support high-speed data transfer protocols like USB 3.0 or USB-C, ensuring faster file transfers and improved efficiency.",
    additionalInfoFeatures: [
      "Multi-Card Compatibility: Supports various types of memory cards, including SD, microSD, CF, and more, providing versatility in accessing different types of digital content",
      "High-Speed Data Transfer: Utilizes high-speed data transfer protocols such as USB 3.0 or USB-C, enabling fast and efficient transfer of files between memory cards and devices",
      "Plug-and-Play Functionality: Requires no additional software installation, offering hassle-free operation and ease of use",
      "Compact and Portable Design: Compact and lightweight design makes it easy to carry and use on the go, ensuring convenient access to digital content wherever you are",
    ],
    additionalInfoHighlight: [
      "Hardware Interface: Supports USB 3.0 and microSDXC for versatile connectivity",
      "Data Transfer Rate: Achieves high-speed transfers at 5 gigabits per second",
      "Operating System Compatibility: Works seamlessly with Android and Linux systems",
      "Media Type Compatibility: Compatible with Memory Stick, UHS-1, SD Card, and CompactFlash formats",
    ],
  },
  {
    id: "product13",
    img: Printer,
    header: "Printer",
    price: "₹500",
    subdescription:
      "A peripheral device that produces text or graphics on paper, often used for document and image reproduction.",
    reviewCount: "(97)",
    description1:
      "A printer is a peripheral device that translates digital content into physical copies on paper or other media. It employs various printing technologies like inkjet, laser, or dot matrix to reproduce text, graphics, or images from digital files stored on computers or devices. Printers range from compact inkjet models for home use to large-format printers for professional tasks, offering features such as color printing, duplexing, scanning, copying, and faxing.",
    description2:
      "They connect to computers or networks through USB, Wi-Fi, Ethernet, or other interfaces, enabling convenient printing from multiple devices within a network. Many printers also support mobile printing, allowing users to print directly from smartphones or tablets.",
    additionalInfoFeatures: [
      "Printing Technologies: Utilizes inkjet, laser, or dot matrix printing technologies to produce high-quality text, graphics, or images on various media types",
      "Functionality: Offers features such as color printing, duplex printing, scanning, copying, and faxing, catering to diverse printing needs",
      "Connectivity Options: Connects to computers or networks via USB, Wi-Fi, Ethernet, or other interfaces, facilitating convenient printing from multiple devices within a network",
      "Mobile Printing: Supports mobile printing, enabling users to print directly from smartphones or tablets, enhancing flexibility and convenience in printing tasks",
    ],
    additionalInfoHighlight: [
      "Output: Monochrome prints",
      "Technology: Laser printing for efficiency",
      "Paper Size: A4 for standard documents",
      "Media Type: Plain paper for versatility",
      "Max Input: 150 sheets to minimize refills",
      "Connectivity: USB and network options for flexibility"
    ],
  },
  {
    id: "product14",
    img: Projector,
    header: "Projector",
    price: "₹500",
    subdescription:
      "A device that displays images or videos onto a screen or surface, commonly used in presentations, home theaters, and classrooms for enhanced visual communication.",
    reviewCount: "(97)",
    description1:
      "A projector is an optical device that displays images or videos onto a screen or surface for viewing by an audience. Utilizing light sources like lamps or LEDs, projectors project digital content from devices such as computers or DVD players onto a larger display area, making them ideal for presentations, movie screenings, or educational purposes.",
    description2:
      "Projectors are available in various types, including LCD, DLP, and LCoS, each offering distinct advantages in image quality and portability. They may feature different resolutions such as HD, Full HD, or 4K to suit different content needs.",
    additionalInfoFeatures: [
      "Projection Technology: Utilizes advanced projection technologies such as LCD, DLP, or LCoS to deliver high-quality images or videos onto a screen or surface",
      "Resolution Options: Offers various resolution options including HD, Full HD, or 4K, ensuring crisp and detailed image reproduction to suit different content requirements",
      "Adjustable Settings: Provides adjustable focus and zoom capabilities, as well as keystone correction to correct image distortion and ensure optimal viewing quality",
      "Connectivity Options: Offers multiple connectivity options such as HDMI, VGA, and wireless connectivity, allowing seamless integration with various devices for versatile usage in different environments",
    ],
    additionalInfoHighlight: [
      "Interface: Supports HDMI, VGA, and AV ports for versatile connectivity",
      "Resolution: Projects in 1080p Full HD for sharp and detailed images",
      "Brightness: Offers 800 lumens for clear projection in various lighting conditions",
      "Native Resolution: Features a native resolution of 1920 x 1080 pixels for high-quality projection",
      "Throw Distance: Allows for a throw distance range from 2 to 6 meters, accommodating different room sizes"
    ],
  },
];

const CategoryProductsData = [
  {
    img: Mobile,
    product: "Mobile",
  },
  {
    img: Laptop,
    product: "Laptop",
  },
  {
    img: Speaker1,
    product: "Speaker",
  },
  {
    img: Appliances,
    product: "Appliances",
  },
  {
    img: Laptop,
    product: "Laptop ",
  },
  {
    img: Appliances,
    product: "Appliances",
  },
  {
    img: Speaker1,
    product: "Speaker",
  },
  {
    img: Mobile,
    product: "Mobile",
  },
];

export { FeatureProductsData, CategoryProductsData };

import * as React from "react";
const Search = (props) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_103_127)">
      <g clipPath="url(#clip1_103_127)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8631 24.2948C20.4285 24.8601 21.0997 25.3086 21.8384 25.6146C22.5771 25.9206 23.3688 26.0781 24.1684 26.0781C24.9679 26.0781 25.7597 25.9206 26.4984 25.6146C27.2371 25.3086 27.9083 24.8601 28.4736 24.2948C29.039 23.7294 29.4875 23.0582 29.7935 22.3195C30.0994 21.5808 30.2569 20.7891 30.2569 19.9895C30.2569 19.1899 30.0994 18.3982 29.7935 17.6595C29.4875 16.9208 29.039 16.2496 28.4736 15.6842C27.3318 14.5424 25.7832 13.9009 24.1684 13.9009C22.5536 13.9009 21.0049 14.5424 19.8631 15.6842C18.7213 16.8261 18.0798 18.3747 18.0798 19.9895C18.0798 21.6043 18.7213 23.1529 19.8631 24.2948ZM19.5894 25.4737C21.0054 26.6596 22.8261 27.2504 24.6686 27.1218C26.5112 26.9932 28.2321 26.1553 29.4697 24.7842C30.7072 23.4131 31.365 21.6156 31.3048 19.7695C31.2446 17.9235 30.471 16.1727 29.1467 14.8852C27.8224 13.5977 26.0505 12.8737 24.2035 12.8655C22.3565 12.8573 20.5782 13.5654 19.2425 14.8411C17.9068 16.1168 17.1177 17.8607 17.0411 19.7061C16.9644 21.5515 17.6062 23.3548 18.8315 24.7369L13.1473 30.4211C13.0926 30.4687 13.0483 30.527 13.0172 30.5926C12.9861 30.6581 12.9688 30.7293 12.9666 30.8018C12.9643 30.8743 12.977 30.9465 13.004 31.0138C13.0309 31.0811 13.0715 31.1422 13.1231 31.1931C13.1747 31.244 13.2364 31.2837 13.3041 31.3097C13.3718 31.3357 13.4441 31.3474 13.5166 31.3441C13.589 31.3408 13.66 31.3225 13.7251 31.2905C13.7901 31.2585 13.8479 31.2133 13.8947 31.1579L19.5894 25.4737Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_103_127">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="matrix(-1 0 0 1 32 12)"
        />
      </clipPath>
      <clipPath id="clip1_103_127">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="matrix(-1 0 0 1 32 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Search;

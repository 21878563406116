import * as React from "react";
const SVGComponent = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <path
        d="M22.9575 7.5C23.9588 7.50563 26.4638 7.53 29.1263 7.63687L30.0713 7.67812C32.7506 7.80375 35.4281 8.02125 36.7575 8.39062C38.5294 8.88937 39.9206 10.3406 40.3912 12.1819C41.1412 15.1069 41.235 20.8106 41.2462 22.1925L41.2481 22.4775V22.8038C41.235 24.1856 41.1412 29.8913 40.3912 32.8144C39.915 34.6613 38.5219 36.1144 36.7575 36.6056C35.4281 36.975 32.7506 37.1925 30.0713 37.3181L29.1263 37.3613C26.4638 37.4663 23.9588 37.4925 22.9575 37.4963L22.5169 37.4981H22.0387C19.92 37.485 11.0588 37.3894 8.23875 36.6056C6.46875 36.1069 5.07563 34.6556 4.605 32.8144C3.855 29.8894 3.76125 24.1856 3.75 22.8038V22.1925C3.76125 20.8106 3.855 15.105 4.605 12.1819C5.08125 10.335 6.47438 8.88187 8.24062 8.3925C11.0588 7.60687 19.9219 7.51125 22.0406 7.5H22.9575ZM18.7481 15.9375V29.0625L29.9981 22.5L18.7481 15.9375Z"
        fill={isHovered ? "#fff" : "#A1A1A1"}
      />
    </svg>
  );
};
export default SVGComponent;
